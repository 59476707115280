import { SvgIcon } from '@mui/material';
import {
    type SidemenuNavigationEntry,
    DashboardIcon,
    SettingsIcon,
    EventsHistoryIcon,
    WaterDropletsIcon,
    GreenhouseIcon,
    HistoryIcon,
    ClimateHistoryIcon,
    NotificationsIcon,
    RainQuantityIcon,
    AccountIcon,
    AirpointIcon,
    SnowIcon,
    ControllerIcon,
    ReportIcon,
    IrrigationHistoryIcon,
} from '@ondo-ui/components';
import { Group, type UserRole } from './constants';

export type SidemenuIconKey =
    | 'alarms'
    | 'dashboard'
    | 'irrigation'
    | 'irrigation_infrastructure'
    | 'fields'
    | 'reports'
    | 'history'
    | 'history_events'
    | 'history_climate'
    | 'history_irrigation'
    | 'settings'
    | 'settings_irrigation_infrastructures'
    | 'settings_fields'
    | 'settings_weather_stations'
    | 'settings_users'
    | 'settings_airpoints'
    | 'settings_alarms'
    | 'settings_frost_protection'
    | 'frost_protection'
    | 'settings_controllers';

export type SidemenuBadgeKey = 'alarms';

export type SidemenuItem = Pick<SidemenuNavigationEntry, 'id' | 'to' | 'badge' | 'isLoading'> & {
    roles: ReadonlyArray<UserRole>;
    items?: SidemenuItem[];
    iconKey?: SidemenuIconKey;
    badgeKey?: SidemenuBadgeKey;
    label?: string;
};

export const sidemenuIconMap = new Map<SidemenuIconKey, React.ElementType<typeof SvgIcon>>([
    ['alarms', NotificationsIcon],
    ['dashboard', DashboardIcon],
    ['irrigation', GreenhouseIcon],
    ['irrigation_infrastructure', WaterDropletsIcon],
    ['history', HistoryIcon],
    ['fields', GreenhouseIcon],
    ['reports', ReportIcon],
    ['history_events', EventsHistoryIcon],
    ['history_irrigation', IrrigationHistoryIcon],
    ['history_climate', ClimateHistoryIcon],
    ['settings_alarms', NotificationsIcon],
    ['frost_protection', SnowIcon],
    ['settings', SettingsIcon],
    ['settings_irrigation_infrastructures', WaterDropletsIcon],
    ['settings_fields', GreenhouseIcon],
    ['settings_weather_stations', RainQuantityIcon],
    ['settings_users', AccountIcon],
    ['settings_airpoints', AirpointIcon],
    ['settings_frost_protection', SnowIcon],
    ['settings_controllers', ControllerIcon],
]);

export const sidemenuItems: SidemenuItem[] = [
    {
        id: 'dashboard',
        to: '/',
        roles: Group.viewers,
        iconKey: 'dashboard',
    },
    {
        id: 'alarms',
        to: '/alarms/active',
        roles: Group.viewers,
        iconKey: 'alarms',
        badgeKey: 'alarms',
    },
    {
        id: 'fields',
        roles: Group.viewers,
        to: '/fields',
        iconKey: 'fields',
    },
    {
        id: 'reports',
        roles: Group.viewers,
        to: '/reports/index',
        iconKey: 'reports',
    },
    {
        id: 'history',
        to: '',
        roles: Group.viewers,
        iconKey: 'history',
        items: [
            {
                to: '/history/climate',
                id: 'climateHistory',
                roles: Group.viewers,
                iconKey: 'history_climate',
            },
            {
                to: '/history/irrigation',
                id: 'irrigationHistory',
                roles: Group.viewers,
                iconKey: 'history_irrigation',
            },
            {
                to: '/history/events',
                id: 'eventsHistory',
                roles: Group.viewers,
                iconKey: 'history_events',
            },
        ],
    },
    {
        id: 'frostProtection',
        roles: Group.viewers,
        to: '/frost-protection',
        iconKey: 'frost_protection',
    },
    {
        id: 'settings',
        to: '',
        roles: Group.viewers,
        iconKey: 'settings',
        items: [
            {
                id: 'irrigationInfrastructures',
                roles: Group.viewers,
                to: '/settings/irrigation-infrastructures',
                iconKey: 'irrigation_infrastructure',
            },
            {
                id: 'fields',
                roles: Group.viewers,
                to: '/settings/fields',
                iconKey: 'settings_fields',
            },
            {
                id: 'airpoints',
                roles: Group.viewers,
                to: '/settings/airpoints',
                iconKey: 'settings_airpoints',
            },
            {
                id: 'controllers',
                roles: Group.viewers,
                to: '/settings/controllers',
                iconKey: 'settings_controllers',
            },
            {
                id: 'alarms',
                roles: Group.viewers,
                to: '/settings/alarms',
                iconKey: 'settings_alarms',
            },
            {
                id: 'frostProtection',
                roles: Group.viewers,
                to: '/settings/frost-protection',
                iconKey: 'settings_frost_protection',
            },
            {
                id: 'weatherStations',
                roles: Group.viewers,
                to: '/settings/weather-stations',
                iconKey: 'settings_weather_stations',
            },
            {
                id: 'users',
                roles: Group.admins,
                to: '/settings/users',
                iconKey: 'settings_users',
            },
            {
                id: 'accounts',
                roles: Group.viewers,
                iconKey: 'settings',
                to: '/settings/accounts',
            },
        ],
    },
];
