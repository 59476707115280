import {
    setLastReadingAt,
    setRainQuantityLast24h,
    setSensorStatus,
    setSensorValue,
    setWeatherStationStatus,
} from '../../slices/weatherStationsSlice';
import { SignalRListener } from '../middleware/listeners';

const onDataUpdatedChanged: SignalRListener<OndoCloudEvents.WeatherStations.WeatherStationDataUpdated> =
    e =>
    ({ dispatch }) =>
        dispatch(setLastReadingAt(e));

const onWeatherStationSensorValueChanged: SignalRListener<
    OndoCloudEvents.WeatherStations.WeatherStationSensorValueChanged
> =
    e =>
    ({ dispatch }) =>
        dispatch(setSensorValue({ sensorId: e.id, value: e.value, wsId: e.weatherStationId }));

const onWeatherStationSensorStatusChanged: SignalRListener<
    OndoCloudEvents.WeatherStations.WeatherStationSensorStatusChanged
> =
    e =>
    ({ dispatch }) =>
        dispatch(setSensorStatus({ sensorId: e.id, wsId: e.weatherStationId, status: e.status }));

const onWeatherStationStatusChanged: SignalRListener<OndoCloudEvents.WeatherStations.WeatherStationStatusChanged> =
    e =>
    ({ dispatch }) =>
        dispatch(setWeatherStationStatus({ wsId: e.id, status: e.status }));

const onWeatherStationRainQuantityLast24HoursChanged: SignalRListener<
    OndoCloudEvents.WeatherStations.WeatherStationRainQuantityLast24HoursChanged
> =
    e =>
    ({ dispatch }) =>
        dispatch(setRainQuantityLast24h({ id: e.id, rainQuantity: e.rainQuantity }));

export const listeners = {
    WeatherStationDataUpdated: onDataUpdatedChanged,
    WeatherStationSensorValueChanged: onWeatherStationSensorValueChanged,
    WeatherStationSensorStatusChanged: onWeatherStationSensorStatusChanged,
    WeatherStationStatusChanged: onWeatherStationStatusChanged,
    WeatherStationRainQuantityLast24HoursChanged: onWeatherStationRainQuantityLast24HoursChanged,
};
