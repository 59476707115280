import { sortByNameAsc } from '../utils/sort';
import { baseApi } from './baseApi';

export interface GetPeripheralsArgs {
    controllerId: string;
}

export const peripheralsApi = baseApi.injectEndpoints({
    endpoints: build => ({
        getPeripherals: build.query<OndoCloudSchemas.PeripheralDto[], Partial<GetPeripheralsArgs> | void>({
            query: params => ({
                method: 'GET',
                url: 'peripherals',
                params: params ?? undefined,
            }),
            transformResponse: (result: OndoCloudSchemas.PeripheralDto[]) => result.sort(sortByNameAsc),
            providesTags: result =>
                result
                    ? [
                          { type: 'Peripherals', id: 'LIST' },
                          ...result.map(p => ({ type: 'Peripherals' as const, id: p.id })),
                      ]
                    : [],
        }),
        getPeripheralById: build.query<OndoCloudSchemas.PeripheralDto, { id: string }>({
            query: ({ id }) => ({
                method: 'GET',
                url: `peripherals/${id}`,
            }),
            providesTags: result =>
                result
                    ? [
                          { type: 'Peripherals', id: 'LIST' },
                          { type: 'Peripherals', id: result.id },
                      ]
                    : [],
        }),
        createPeripheral: build.mutation<OndoCloudSchemas.PeripheralDto, OndoCloudSchemas.CreatePeripheralCommand>({
            query: body => ({
                method: 'POST',
                url: `peripherals`,
                body,
            }),
            invalidatesTags: result =>
                result
                    ? [
                          { type: 'Peripherals', id: 'LIST' },
                          { type: 'Peripherals', id: result.id },
                      ]
                    : [],
        }),
        updatePeripheral: build.mutation<
            OndoCloudSchemas.PeripheralDto,
            OndoCloudSchemas.EditPeripheralCommand & { id: string }
        >({
            query: ({ id, ...body }) => ({
                method: 'PUT',
                url: `peripherals/${id}`,
                body,
            }),
            invalidatesTags: result =>
                result
                    ? [
                          { type: 'Peripherals', id: 'LIST' },
                          { type: 'Peripherals', id: result.id },
                      ]
                    : [],
        }),
        deletePeripheral: build.mutation<void, { id: string }>({
            query: ({ id }) => ({
                method: 'DELETE',
                url: `peripherals/${id}`,
            }),
            invalidatesTags: (_, error, arg) =>
                !error
                    ? [
                          { type: 'Peripherals', id: 'LIST' },
                          { type: 'Peripherals', id: arg.id },
                      ]
                    : [],
        }),
    }),
    overrideExisting: true,
});

export const {
    useGetPeripheralsQuery,
    useGetPeripheralByIdQuery,
    useCreatePeripheralMutation,
    useUpdatePeripheralMutation,
    useDeletePeripheralMutation,
} = peripheralsApi;
