import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SensorType, WeatherStationStatus } from '~/app/constants';
import { RootState } from '../app/store';
import { weatherStationsApi } from '../services/weatherStations';

function groupSensorsById(sensors: OndoCloudSchemas.WeatherStationStateResp['sensors']) {
    return sensors.reduce((grouped, current) => {
        return { ...grouped, [current.id]: { ...current, status: WeatherStationStatus.Connected } };
    }, {});
}
const initialState: Record<string, OndoCloudState.WeatherStationState> = {};

export const weatherStationsSlice = createSlice({
    name: 'weatherStations',
    initialState,
    reducers: {
        sync(
            draft,
            { payload: weatherStationsById }: PayloadAction<Record<string, OndoCloudSchemas.WeatherStationStateResp>>
        ) {
            for (const weatherStation of Object.values(weatherStationsById)) {
                const sensorsGroupedById = groupSensorsById(weatherStation.sensors);

                draft[weatherStation.id] = {
                    id: weatherStation.id,
                    controllerId: weatherStation.controllerId,
                    lastUpdatedAt: weatherStation.lastUpdatedAt,
                    sensors: sensorsGroupedById,
                    rainQuantityLast24Hours: weatherStation.rainQuantityLast24Hours ?? null,
                    status: weatherStation.status,
                };
            }
        },

        setLastReadingAt(draft, { payload }: PayloadAction<OndoCloudEvents.WeatherStations.WeatherStationDataUpdated>) {
            if (!draft[payload.id]) {
                return;
            }
            draft[payload.id].lastUpdatedAt = payload.updatedAt;
        },

        setSensorValue(draft, { payload }: PayloadAction<{ wsId: string; sensorId: string; value: number }>) {
            if (!draft[payload.wsId].sensors[payload.sensorId]) {
                return;
            }

            draft[payload.wsId].sensors[payload.sensorId].value = payload.value;
        },
        setSensorStatus(
            draft,
            { payload }: PayloadAction<{ wsId: string; sensorId: string; status: WeatherStationStatus }>
        ) {
            if (!draft[payload.wsId].sensors[payload.sensorId]) {
                return;
            }
            draft[payload.wsId].sensors[payload.sensorId].status = payload.status;
        },

        setWeatherStationStatus(draft, { payload }: PayloadAction<{ wsId: string; status: WeatherStationStatus }>) {
            if (!draft[payload.wsId]) {
                return;
            }
            draft[payload.wsId].status = payload.status;
        },

        setRainQuantityLast24h(draft, { payload }: PayloadAction<{ id: string; rainQuantity: number }>) {
            if (!draft[payload.id]) {
                return;
            }
            draft[payload.id].rainQuantityLast24Hours = payload.rainQuantity;
        },
    },

    extraReducers: builder => {
        builder.addMatcher(
            weatherStationsApi.endpoints.createWeatherStation.matchFulfilled,
            (draft, { payload: weatherStation }) => {
                if (draft[weatherStation.id]) {
                    return;
                }

                const sensors =
                    weatherStation.sensors?.map(sensor => {
                        return {
                            ...sensor,
                            status: WeatherStationStatus.Disconnected,
                            lastReadAt: undefined,
                            value: undefined,
                        };
                    }) ?? [];

                draft[weatherStation.id] = {
                    id: weatherStation.id,
                    controllerId: weatherStation.connection?.peripheral?.controllerId,
                    lastUpdatedAt: null,
                    rainQuantityLast24Hours: null,
                    sensors: groupSensorsById(sensors),
                    status: WeatherStationStatus.Connected,
                };
            }
        );

        builder.addMatcher(
            weatherStationsApi.endpoints.updateWeatherStation.matchFulfilled,
            (draft, { payload: weatherStation }) => {
                const currentWeatherStation = draft[weatherStation.id];
                if (!currentWeatherStation || !weatherStation.connection) {
                    return;
                }
                currentWeatherStation.controllerId = weatherStation.connection.peripheral?.controllerId;
            }
        );

        builder.addMatcher(weatherStationsApi.endpoints.deleteWeatherStation.matchFulfilled, (draft, { meta }) => {
            delete draft[meta.arg.originalArgs];
        });
    },
});

// Selectors

export const selectWeatherStations = (state: RootState) => state.weatherStations;
export const selectWeatherStationById = createSelector(
    selectWeatherStations,
    (_: RootState, id: string) => id,
    (weatherStations, id: string): OndoCloudState.WeatherStationState | null => weatherStations[id]
);

export const selectWeatherStationLastUpdatedAt = createSelector(
    selectWeatherStationById,
    weatherStation => weatherStation?.lastUpdatedAt
);

export const selectWeatherStationStatus = createSelector(selectWeatherStationById, weatherStation =>
    weatherStation ? weatherStation.status : WeatherStationStatus.Disconnected
);

export const selectWeatherStationRainQuantityLast24h = createSelector(
    selectWeatherStationById,
    weatherStation => weatherStation?.rainQuantityLast24Hours
);

export const selectWeatherStationSensors = createSelector(
    selectWeatherStationById,
    (weatherStation): OndoCloudState.WeatherStationState['sensors'] | null => weatherStation?.sensors ?? {}
);

export const selectWeatherStationSensorById = createSelector(
    selectWeatherStationSensors,
    (_: RootState, weatherStaionId: string, sensorId: string) => sensorId,
    (sensors, sensorId: string): OndoCloudSchemas.WeatherStationSensorStateResp | null =>
        sensors ? sensors[sensorId] : null
);

export const selectWeatherStationSensorStatus = createSelector(selectWeatherStationSensorById, sensor =>
    sensor ? sensor.status : WeatherStationStatus.Disconnected
);

export const selectWeatherStationSensorValue = createSelector(selectWeatherStationSensorById, sensor => sensor?.value);

export const selectWeatherStationSensorLastRead = createSelector(selectWeatherStationSensors, weatherStation =>
    weatherStation
        ? Object.values(weatherStation).find(sensor => sensor.type === SensorType.Temperature)?.lastReadAt
        : undefined
);

export const selectWeatherStationSensorValueByType = createSelector(
    selectWeatherStationSensors,
    (_: RootState, weatherStaionId: string, type: SensorType) => type,
    (sensors, type: SensorType): number | undefined =>
        sensors ? Object.values(sensors).find(sensor => sensor.type === type)?.value : undefined
);

export const {
    sync,
    setSensorValue,
    setSensorStatus,
    setLastReadingAt,
    setRainQuantityLast24h,
    setWeatherStationStatus,
} = weatherStationsSlice.actions;
