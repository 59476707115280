import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PeripheralStatus } from '../app/constants';
import { RootState } from '../app/store';
import { peripheralsApi } from '../services/peripheralsService';

export type PeripheralStateItem = Pick<
    OndoCloudState.PeripheralState,
    'controllerId' | 'status' | 'batteryLevel' | 'batteryUpdatedAt'
>;
export type PeripheralsState = Record<string, PeripheralStateItem>;

const initialState: PeripheralsState = {};

export const peripheralsSlice = createSlice({
    name: 'peripherals',
    initialState,
    reducers: {
        sync(draft, { payload: peripherals }: PayloadAction<OndoCloudState.RootState['peripheralState']>) {
            for (const [id, peripheral] of Object.entries(peripherals)) {
                draft[id] = {
                    status: peripheral.status,
                    controllerId: peripheral.controllerId,
                };
            }
        },

        addPeripheral(draft, { payload }: PayloadAction<{ id: string } & PeripheralStateItem>) {
            const peripheral = draft[payload.id];
            if (peripheral) {
                return;
            }

            draft[payload.id] = {
                status: payload.status,
                controllerId: payload.controllerId,
            };
        },

        removePeripheral(draft, { payload }: PayloadAction<{ id: string }>) {
            delete draft[payload.id];
        },

        setPeripheralStatus(draft, { payload }: PayloadAction<{ id: string; status: PeripheralStatus }>) {
            const peripheral = draft[payload.id];
            if (!peripheral) {
                return;
            }

            peripheral.status = payload.status;
        },

        setAirpointPeripheralBatteryLevel(
            draft,
            { payload }: PayloadAction<{ id: string; batteryLevel: number; batteryUpdatedAt: string }>
        ) {
            const peripheral = draft[payload.id];
            if (!peripheral) {
                return;
            }

            peripheral.batteryLevel = payload.batteryLevel;
            peripheral.batteryUpdatedAt = payload.batteryUpdatedAt;
        },
    },

    extraReducers: builder => {
        builder.addMatcher(
            peripheralsApi.endpoints.createPeripheral.matchFulfilled,
            (draft, { payload: peripheral }) => {
                draft[peripheral.id] = {
                    status: PeripheralStatus.Connected,
                    controllerId: peripheral.controllerId,
                };
            }
        );

        builder.addMatcher(
            peripheralsApi.endpoints.updatePeripheral.matchFulfilled,
            (draft, { payload: peripheral }) => {
                draft[peripheral.id] = {
                    status: PeripheralStatus.Connected,
                    controllerId: peripheral.controllerId,
                };
            }
        );

        builder.addMatcher(peripheralsApi.endpoints.deletePeripheral.matchFulfilled, (draft, { meta }) => {
            const id = meta.arg.originalArgs.id;
            delete draft[id];
        });
    },
});

// Selectors

export function selectPeripherals(state: RootState) {
    return state.peripherals;
}

export const selectPeripheralById = createSelector(
    selectPeripherals,
    (_: RootState, id: string) => id,
    (peripherals, id): PeripheralStateItem | null => peripherals[id]
);

export const selectPeripheralStatus = createSelector(
    selectPeripheralById,
    (peripheral): PeripheralStatus | null => peripheral?.status ?? null
);

export const selectPeripheralBatteryLevel = createSelector(
    selectPeripheralById,
    (peripheral): number | null => peripheral?.batteryLevel ?? null
);

export const selectPeripheralLastBatteryRead = createSelector(
    selectPeripheralById,
    (peripheral): string | null => peripheral?.batteryUpdatedAt ?? null
);

export const { sync, addPeripheral, removePeripheral, setPeripheralStatus, setAirpointPeripheralBatteryLevel } =
    peripheralsSlice.actions;
